<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'

interface Props {
  open: boolean
  disabled?: boolean
  placement?: DropdownPlacement
  direction?: DropdownDirection
  classes?: {
    trigger?: string
    dropdown?: string
  }
  buttonText?: string
  buttonTheme?: ButtonThemes
  hideButton?: boolean
}

const {
  direction = DROPDOWN_DIRECTION.DOWN,
  disabled = false,
  classes = {},
  placement,
  buttonText = '',
  buttonTheme = BUTTON_THEME.SECONDARY,
  hideButton = false,
} = defineProps<Props>()

const emit = defineEmits(['open', 'close'])

const cssClasses = computed(() => {
  const { trigger, dropdown } = classes

  return {
    trigger: trigger ?? '!w-auto shrink-0 overflow-hidden',
    dropdown: dropdown ?? 'md:w-72 max-h-96',
  }
})

const breakpoints = useBreakpoints(breakpointsTailwind)
const mdAndLarger = breakpoints.greaterOrEqual('md')

const triggerId = useId()

defineOptions({
  name: 'Dropdown',
})
</script>

<template>
  <div :data-dropdown-open="open">
    <slot name="trigger" :trigger-id>
      <DropdownButton
        v-if="!hideButton"
        :open
        :direction
        :disabled
        :text="buttonText"
        :theme="buttonTheme"
        :size="BUTTON_SIZE.BASE"
        :class="cssClasses.trigger"
        @open="emit('open')"
        @close="emit('close')"
        :id="triggerId"
      />
    </slot>

    <LazyDropdownContentOffCanvas
      v-if="!mdAndLarger && open"
      @close="emit('close')"
    >
      <template #header>
        <slot name="header" />
      </template>
      <template #default>
        <slot name="default" />
      </template>
      <template #footer>
        <slot name="footer" />
      </template>
    </LazyDropdownContentOffCanvas>

    <LazyDropdownContentOnPage
      v-if="mdAndLarger && open"
      :direction
      :placement
      :classes="{ dropdown: cssClasses.dropdown }"
      :trigger-id
      @close="emit('close')"
    >
      <template #header>
        <slot name="header" />
      </template>
      <template #default>
        <slot name="default" />
      </template>
      <template #footer>
        <slot name="footer" />
      </template>
    </LazyDropdownContentOnPage>
  </div>
</template>
