<script setup lang="ts">
import { onClickOutside, useElementBounding, useScroll } from '@vueuse/core'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  limitShift,
} from '@floating-ui/vue'

interface Props {
  direction?: DropdownDirection
  placement?: DropdownPlacement
  classes?: {
    trigger?: string
    dropdown?: string
  }
  triggerId: string
}

const { DOWN, UP } = DROPDOWN_DIRECTION

const {
  direction = DROPDOWN_DIRECTION.DOWN,
  classes = {},
  placement = undefined,
  triggerId,
} = defineProps<Props>()

const emit = defineEmits(['close'])

const { open, close, cssClasses } = useDropdown({
  classes: {
    ...classes,
    container: `
      ${direction === DOWN ? 'bottom-0' : ''}
      ${direction === UP ? 'top-0' : ''}
    `,
  },
  style: DROPDOWN_STYLE.ON_PAGE,
  onClose: () => emit('close'),
})

const triggerElement = ref<HTMLElement | null>(
  document.getElementById(triggerId),
)
const trigger = ref()
const dropdown = ref()

const { floatingStyles } = useFloating(triggerElement, dropdown, {
  placement: placement
    ? placement
    : direction === DOWN
      ? 'bottom-start'
      : 'top-start',
  middleware: [
    offset(),
    flip(),
    shift({
      padding: 8,
      crossAxis: true,
      limiter: limitShift(),
    }),
  ],
  whileElementsMounted: autoUpdate,
})

function isDropdownElement(el: EventTarget): boolean {
  return (
    el instanceof HTMLElement &&
    el.hasAttribute('data-dropdown-open') &&
    el.getAttribute('data-dropdown-open') === 'false'
  )
}

function isActiveDropdownElement(el: EventTarget): boolean {
  return (
    el instanceof HTMLElement &&
    el.hasAttribute('data-dropdown-open') &&
    el.getAttribute('data-dropdown-open') === 'true'
  )
}

onClickOutside(dropdown, (event) => {
  const composedPath = event?.composedPath()

  const clickedOnDropdown = composedPath.some(isDropdownElement)
  const clickedOnTrigger = composedPath.some(isActiveDropdownElement)

  if (clickedOnDropdown || !(clickedOnDropdown || clickedOnTrigger)) {
    close()
  }
})

onMounted(async () => {
  await nextTick()
  open()
})

defineOptions({
  name: 'DropdownContentOnPage',
})
</script>

<template>
  <div ref="trigger" :class="cssClasses.container" data-dropdown-container>
    <div
      ref="dropdown"
      :class="cssClasses.dropdown"
      data-dropdown-content
      :style="floatingStyles"
    >
      <div :class="cssClasses.header" data-dropdown-header>
        <slot name="header" />
      </div>
      <div :class="cssClasses.content">
        <slot name="default" />
      </div>
      <div :class="cssClasses.footer" data-dropdown-footer>
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>
